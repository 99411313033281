import AppBody from "components/AppBody";
import AppContainer from "components/AppContainer";
import Header from "components/Sections/Header";
import Footer from "components/Sections/Footer";
import StaffCard from "components/UI/StaffCard";

import "./index.scoped.css"

function ContactPage() {
    const header = [
        { title: "О нас", link: "/about" },
        { title: "Контакты", link: "/contacts" },
        { title: "Услуги", link: "/services?service=inheritance" },
        { title: "Тарифы", link: "/tariffs" }
    ];

    const description = "Назначена на должность нотариуса Управлением Министерства юстиции Российской Федерации по С-Петербургу и Ленинградской области. Лицензия на право нотариальной деятельности N453 от 05 апреля 2004г.";

    return (
        <AppBody>
            <Header links={header} />
            <div className="block__wrapper">

                <div className="about__block">
                    <AppContainer>

                        <div className="about__wrapper">

                            <div className="about__text">
                                <h2>Нотариальная контора на Фермском</h2>
                                <p>В нашей нотариальной конторе мы оказываем весь спектр нотариальных услуг. Прием граждан осуществляется как по записи, так и посредством живой очереди. Также вы всегда можете обратиться в нотариальную контору за консультацией для разрешения вашего вопроса.
                                    <br/><br/>
                                    Нотариальная контора на Фермском шоссе находится в пешей доступности от станции метро Удельная. Вход осуществляется со стороны улицы Аккуратова. 6. </p>
                                    <br/>
                                    График работы нотариальной конторы:
                                    <ul>
                                        <li>ПН-ПТ: с 09:00 до 19:00</li>
                                        <li>Обед:  с 13:00 до 14:00</li>
                                        <li>СБ:    с 10:00 до 17:00</li>
                                        <li>ВС:    Выходной</li>
                                    </ul>
                            </div>

                            <div className="about__gallery">
                                <div id="img1" className="gallery__img"></div>
                                <div id="img2" className="gallery__img"></div>
                                <div id="img3" className="gallery__img"></div>
                                <div id="img4" className="gallery__img"></div>
                            </div>

                        </div>
        
                    </AppContainer>
                </div>

                <div className="staff__block">
                    <AppContainer>

                        <div className="staff__wrapper">

                            <h2>Нотариус</h2>

                            <div className="card__wrapper">
                                <StaffCard name={"Лебедева Наталия Николаевна"} description={description} img={"staff1.jpg"}/>
                            </div>
                            
                            <h2>Временно исполняющие обязанности нотариуса</h2>
                            <div className="text__wrapper">
                                <h3>Агафонова Наталья Александровна</h3>
                                <h3>Зубарева Мария Александровна</h3>
                            </div>
                        </div>
        
                    </AppContainer>
                </div>

            </div>

            <Footer />
        </AppBody>
    )
}

export default ContactPage;