import { useNavigate } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

import "./index.scoped.css"

// Button for web (keeps a link to navigate to smb)

function Button(props) {
    const navigate = useNavigate();

    return (
        <a className="button__primary" href={process.env.PUBLIC_URL + props.path}>{props.text}</a>
    );
}

export default Button;