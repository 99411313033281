import AppBody from "components/AppBody";
import Header from "components/Sections/Header";
import Contacts from "components/Sections/Contacts";
import Form from "components/Sections/Form";
import Footer from "components/Sections/Footer";

import "./index.scoped.css"

function ContactPage() {
    const header = [
        {title: "О нас", link: "/about"},
        {title: "Контакты", link: "/contacts"},
        {title: "Услуги", link: "/services?service=inheritance"},
        {title: "Тарифы", link: "/tariffs"}
    ];
    
    return (
        <AppBody>
            <Header links={header} />
            <div className="block__wrapper">
                <Contacts />
                <Form />
            </div>
            <Footer />
        </AppBody>
    )
}

export default ContactPage;