import AppBody from "components/AppBody";
import AppContainer from "components/AppContainer";
import Header from "components/Sections/Header";
import Contacts from "components/Sections/Contacts";
import ServiceCard from "components/UI/ServiceCard";
import Form from "components/Sections/Form";
import Button from "components/UI/Button";
import Footer from "components/Sections/Footer";

import "./index.scoped.css";

function Main() {
    const header = [
        {title: "О нас", link: "#about"},
        {title: "Контакты", link: "/contacts"},
        {title: "Услуги", link: "#services"},
        {title: "Тарифы", link: "/tariffs"}
    ];

    return (
        <AppBody>
            <Header links={header} />

            <div className="block__wrapper">
                <div className="greeting__block">
                    <div className="tinting"></div>
                    <AppContainer>
                        <div className="greeting__wrapper">
                            <h1>Нотариальные услуги в <span className="__primary">Санкт-Петербурге</span></h1>
                            {/* <h3>Lorem <span className="__primary">ipsum</span> dolor sit amet, <span className="__primary">consectetuer.</span></h3>
                            <p>Lorem ipsum dolor sit amet, <span className="__primary">consectetuer adipiscing</span> elit, sed diam nonummy nibh euismod <span className="__primary">tincidunt</span> ut laoreet dolore magna aliquam erat volutpat. <span className="__primary">Duis autem vel eum</span> iriure dolor in hendrerit.</p> */}
                            {/*<Button text={"Наши услуги"} path={"/services?service=inheritance"} /> */}
                        </div>
                    </AppContainer>
                </div>

                <div className="about__us__block" id="about">
                    <AppContainer>
                        <div className="about__us__wrapper">
                            <h2>О нас</h2>

                            {/*<p className="text">Функция нотариуса заключается в том, чтобы служить связующим звеном между российской правовой системой с другими системами, чтобы ваши документы могли быть подтверждены и использованы где угодно с помощью механизма, называемого легализацией.
                                <br></br>
                                <br></br>Мы стремимся предоставлять срочные услуги всем своим клиентам.</p>*/}

                            <div className="about__us__cards">
                                <div className="card notary">
                                    <div className="text__wrapper">
                                        <h3>Нотариус</h3>
                                        <p>Лебедева Наталия Николаевна - нотариус нотариального округа Санкт-Петербург.
                                            <br></br><br></br>
                                            <a className="__primary" href={`${process.env.PUBLIC_URL}` + "/about"}>Исполняющие обязанности</a></p>
                                    </div>
                                    <div className="tinting"></div>
                                </div>
                                <div className="card office">
                                    <div className="text__wrapper">
                                        <h3>Офис</h3>
                                        <p>5 минут пешком от станции метро Удельная,<br/>прием граждан с <span className="__primary">понедельника</span> по <span className="__primary">субботу.</span></p>
                                    </div>
                                    <div className="tinting"></div>
                                </div>

                            </div>
                            <Button text={"Подробнее"} path={"/about"} />

                        </div>
                    </AppContainer>
                </div>

                <div className="service__block" id="services">
                    <AppContainer>
                        <div className="service__wrapper">
                            <h2>Нотариальные действия</h2>
                            <div className="service__cards__wrapper">
                                <ServiceCard link={"inheritance"} img="1.svg" name={"Наследство"} />
                                <ServiceCard link={"attorney"} img="2.svg" name={"Доверенность"} />
                                <ServiceCard link={"legal"} img="3.svg" name={"Юридические лица"} />
                                <ServiceCard link={"family"} img="4.svg" name={"Семейные отношения"} />
                                <ServiceCard link={"contract"} img="5.svg" name={"Договоры"} />
                                <ServiceCard link={"another"} img="6.svg" name={"Иные нотариальные действия"} />
                            </div>
                        </div>
                    </AppContainer>
                </div>

                <Contacts />

                <Form />
            </div>

            <Footer />

        </AppBody>
    );
}

export default Main;