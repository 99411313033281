import AppContainer from "components/AppContainer";
import ContactOption from "components/UI/ContactOption";

import "./index.scoped.css"

function Contacts() {

    return (
        <div className="contacts__block" id="contacts">
            <AppContainer>
                <div className="contacts__wrapper">

                    <h2>Контакты</h2>

                    <div className="contacts__card">

                        <div className="contacts__info">
                            <div className="wrapper">
                                <ContactOption link={false} title={"Адрес:"} info={["197341, город Санкт-Петербург,", "Фермское шоссе, 32"]} img={"address.svg"} />
                                <ContactOption link={false} title={"Почта:"} info={["9222698@mail.ru"]} img={"mail.svg"} />
                                <ContactOption link={false} title={"Телефоны:"} info={["+7 (911) 922-26-98 — Телеграм", "+7 (812) 308-99-68"]} img={"phone.svg"} />
                                <ContactOption link={false} title={"Часы работы:"} info={["Пн-Пт с 9:00 до 19:00", "Сб с 10:00 до 17:00"]} img={"clock.svg"} />
                                <ContactOption link={true} title={"Группа ВК:"} info={["https://vk.com/lawlebedevann"]} img={"vk.svg"} />
                            </div>
                        </div>

                        <div className="contacts__map">
                        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab5c8e9136976bff9f57a68ffbdcdf5b9ae268f11a24a15f78bd5f4561c03b75e&amp;source=constructor" width="50%" height="100%" frameBorder="0"></iframe>
                        </div>
                    </div>

                </div>
            </AppContainer>
        </div>
    );
}

export default Contacts;