import "./index.scoped.css"

// Card with staff photo and description

function StaffCard(props) {
    return (
        <div className="stuff__card__wrapper">
            <div className="stuff__card" style={{backgroundImage: `url(images/${props.img})`}}>
                <div className=""></div>
            </div>
            <div className="stuff__card__text">
                <h3>{props.name}</h3>
                <p>{props.description}</p>
                <br />
                <p>В нотариальной сфере с 2001 года.</p>
            </div>
        </div>
    );
}

export default StaffCard;