import AppContainer from "components/AppContainer";

import "./index.scoped.css"

function Footer() {

    return (
        <div className="footer">
            <AppContainer>
                <div className="footer__wrapper">

                        <h3 id="title1">Нотариус Лебедева Н.Н.</h3>
                        <h3 id="title2">Нотариальная палата Санкт-Петербурга</h3>
                        <h3 id="title3">Полезные ссылки</h3>
                        
                        <div id="separator1" className="footer__separator"></div>
                        <div id="separator2" className="footer__separator"></div>
                        <div id="separator3" className="footer__separator"></div>
                        
                        <div id="info1" className="footer__info">
                            <ul>
                                <li><span>&nbsp;&nbsp;Приказ № 453 Управления Министерства юстиции Российской Федерации по городу Санкт-Петербургу от 05 апреля 2004 года "О наделении Лебедевой Наталии Николаевны полномочиями нотариуса"</span></li>
                            </ul>
                        </div>
                        <div id="info2" className="footer__info">
                            <ul className="ul__icon">
                                <li className="info__img"><div className="img__1"></div><span>+7 (812) 271-50-95.</span></li>
                                <li className="info__img"><div className="img__2"></div><span>191024, Санкт-Петербург, Конная ул. д. 13.</span></li>
                                <li className="info__img"><div className="img__3"></div><span><a href="https://78.notariat.ru/ru-ru/">https://78.notariat.ru/ru-ru/</a></span></li>
                            </ul>
                        </div>
                        <div id="info3" className="footer__info">
                            <ul className="ul__icon">
                                <li className="info__img"><div className="img__3"></div><span>Федеральная нотариальная палата:<br/><a href="https://notariat.ru/">https://notariat.ru/</a></span></li>
                                <li className="info__img"><div className="img__3"></div><span>Управление министерства юстиции:<br/><a href="https://minjust.gov.ru/">https://minjust.gov.ru/</a></span></li>
                            </ul>
                        </div>

                </div>
            </AppContainer>
        </div>
    );
}

export default Footer;