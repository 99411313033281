import { useRef, useState } from "react";
import AppContainer from "components/AppContainer";
import emailjs from "@emailjs/browser";
import { Toast } from "primereact/toast";
import validator from "validator";
import { InputMask } from "primereact/inputmask";


import "./index.scoped.css"

function Form() {
    const SERVICE_ID = "service_3kvxnj9";
    const TEMPLATE_ID = "template_oeo1a4t";
    const PUBLIC_KEY = "L6GyTPlluAwsnfjii";

    const form = useRef();
    const toast = useRef();

    const [nameInput, setNameInput] = useState("");
    const [phoneInput, setPhoneInput] = useState("");
    const [mailInput, setMailInput] = useState("");
    const [message, setMessageInput] = useState("");

    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    function sendEmail(e) {
        e.preventDefault();

        if (nameInput === "" || nameInput == null) {
            toast.current.show({ sticky: false, life: 2000, closable: false, severity: "error", summary: "Ошибка", detail: "Введите имя" });
            return;
        }

        if (!validator.isMobilePhone(phoneInput.replaceAll("-", "").replace("(", "").replace(")", "").replaceAll(" ", "").replace("+7", "8"))) {
            toast.current.show({ sticky: false, life: 2000, closable: false, severity: "error", summary: "Ошибка", detail: "Формат телефона неверен" });
            return;
        }

        if (!mailInput.match(validEmailRegex)) {
            toast.current.show({ sticky: false, life: 2000, closable: false, severity: "error", summary: "Ошибка", detail: "Электронная почта неверна" });
            return;
        }

        if (message === "" || message == null) {
            toast.current.show({ sticky: false, life: 2000, closable: false, severity: "error", summary: "Ошибка", detail: "Введите сообщение" });
            return;
        }

        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
            .then((result) => {

                setNameInput("");
                setMailInput("");
                setMessageInput("");
                setPhoneInput("+7");

                console.log("success");
                toast.current.show({ sticky: false, life: 2000, closable: false, severity: "info", summary: "Успех", detail: "Сообщение отправлено" });
            }, (error) => {
                toast.current.show({ sticky: false, life: 2000, closable: false, severity: "info", summary: "Ошибка", detail: "Произошла ошибка" });
            });
    }

    return (
        <div className="form__block">
            <AppContainer>
                <div className="form__wrapper">

                    <h2>Остались вопросы?</h2>

                    <form ref={form} className="form" method="POST" onSubmit={sendEmail}>
                        <div className="form__data">

                            <div className="form__item">
                                <input className="form__input" type="text" name="name" id="form__name" value={nameInput} placeholder="Ваше имя" onChange={(e) => { setNameInput(e.target.value); }} />
                                <i className="fas user"></i>
                            </div>

                            <div className="form__item">
                                <InputMask className="form__input" id="phone" name="phone" mask="+7 (999) 999-99-99" placeholder="+7 (999) 999-99-99" value={phoneInput} onChange={(e) => { setPhoneInput(e.target.value); }}></InputMask>
                                {/* <input className="form__input" type="phone" name="phone" id="form__phone" value={phoneInput} placeholder="Номер телефона (+79991234567)" onChange={(e) => { setPhoneInput(e.target.value); }} /> */}
                                <i className="fas phone"></i>
                            </div>

                            <div className="form__item">
                                <input className="form__input" type="email" name="email" id="form__mail" value={mailInput} placeholder="Электронная почта" onChange={(e) => { setMailInput(e.target.value); }} />
                                <i className="fas mail"></i>
                            </div>

                        </div>

                        <div className="form__question">

                            <div className="form__item">
                                <textarea className="form__input" type="text" name="message" id="form__quesion" value={message} placeholder="Ваш вопрос" onChange={(e) => { setMessageInput(e.target.value); }} />
                                <i className="fas message"></i>
                            </div>

                            <div className="button__wrapper button__primary" >
                                <button className="button__primary" type="submit" value=""></button>
                                <p className="button__text">Отправить</p>
                            </div>

                        </div>
                    </form>

                    <div className="toast-block">
                        <Toast ref={toast} position="bottom-right" />
                    </div>


                </div>
            </AppContainer>
        </div>
    );
}

export default Form;