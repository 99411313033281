import "./index.scoped.css"

// Contact card keeps name of img in icons folder and text

function ContactOption(props) {
    return (
        <div className="contact__option">
            <div className="contact__option__image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/icons/${props.img})`}}/>
            <div className="contact__option__info">
                <h3>{props.title}</h3>
                { 
                    (props.link == true) && props.info.map(
                        (text, key) => (
                            <a key={key} href={text} target="_blank">
                                {text}
                            </a>
                        )
                    )
                
                }

                { 
                    (props.link == false) && props.info.map(
                        (text, key) => (
                            <p key={key}>
                                {text}
                            </p>
                        )
                    )
                
                }
            </div>
        </div>
    ); 
}

export default ContactOption;