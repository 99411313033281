import AppBody from "components/AppBody";
import AppContainer from "components/AppContainer";
import Header from "components/Sections/Header";
import Footer from "components/Sections/Footer";
import TariffTable from "components/UI/TariffTable";

import "./index.scoped.css"

function TariffsPage(props) {
    const header = [
        { title: "О нас", link: "/about" },
        { title: "Контакты", link: "/contacts" },
        { title: "Услуги", link: "/services?service=inheritance" },
        { title: "Тарифы", link: "/tariffs" }
    ];

    return (
        <AppBody>
            <Header links={header} />
            <div className="block__wrapper">

                <div className="tariffs__block">
                    <AppContainer>

                        <div className="tariffs__wrapper">
                            <h2>Тарифы</h2>
                            <p>Таблица с официальным документом и всеми тарифами. Тарифы понятно и доступно продублированы на странице с улугами.</p>
                            <TariffTable />
                        </div>

                    </AppContainer>
                </div>
            </div>
            <Footer />
        </AppBody>
    );
}

export default TariffsPage;