import "./index.scoped.css"

// Service card keeps name of image in icons folder and text(name)

function ServiceCard(props) {
    return (
        <a className="service__card" href={`${process.env.PUBLIC_URL}` + "/services?service=" + props.link}>
            <div className="service__image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/icons/${props.img})`}}/>
            <h3>{props.name}</h3>
        </a>
    );
}

export default ServiceCard;