import AppContainer from "components/AppContainer";
import { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';

import "./index.scoped.css"

function Header(props) {
    const [active, setActive] = useState(false);

    return (
        <div className={"header" + (active ? " active" : "")}>
            <AppContainer>
                <div className="header__wrapper">
                    <Link className="link" to="/.">
                        <img className="logo__image" src={require('./logo.png')} alt="logo" />
                        <div className="logo__name">Нотариус Лебедева Наталия Николаевна</div>
                    </Link>
                    <div className="burger__menu__container" id="burger-menu-container" onClick={(e) => { setActive(!active); }}>
                        <div className="burger__menu burger__menu__1"></div>
                        <div className="burger__menu burger__menu__2"></div>
                    </div>
                    <ul className="header__nav">
                        {props.links.map(
                            (item, key) => (
                                <li key={key}><Link className="link" to={process.env.PUBLIC_URL + item.link}>{item.title}</Link></li>
                            )
                        )}
                    </ul>
                </div>
                <div className="burger__content">
                    <ul className="header__burger__nav">
                        {props.links.map(
                            (item, key) => (
                                <li key={key}><Link className="link" to={process.env.PUBLIC_URL + item.link}>{item.title}</Link></li>
                            )
                        )}
                    </ul>
                </div>

            </AppContainer>
        </div>
    );
}

export default Header;