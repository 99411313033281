import { HashLink as Link } from 'react-router-hash-link';

import "./index.scoped.css"

// Like a service card but can be selected

function ServiceLink(props) {
    function isActive() {
        return (props.color ? "icons/": "icons/dark_") + props.img;
    }
    return (
        <Link className={"service__link" + (props.color ? " active": "")} to={`${process.env.PUBLIC_URL}` + "/services?service=" + props.link}>
            <div className="service__image" style={{backgroundImage: `url(${isActive()})`}} />
            <h3 className="service__name">{props.name}</h3>
        </Link>
    );
}

export default ServiceLink;