import "./index.scoped.css"

// Table from excel, nothing to say)

function TariffTable(props) {
    return (
        <>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta
                name="generator"
                content="PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet"
            />
            <title>Untitled Spreadsheet</title>
            <meta name="author" content="Unknown Creator" />
            <meta name="title" content="Untitled Spreadsheet" />
            <meta name="company" content="Microsoft Corporation" />
            
            <table
                border={0}
                cellPadding={0}
                cellSpacing={0}
                id="sheet0"
                className="sheet0 gridlines"
            >
                <colgroup>
                    <col className="col0" />
                    <col className="col1" />
                    <col className="col2" />
                    <col className="col3" />
                    <col className="col4" />
                    <col className="col5" />
                </colgroup>
                <tbody>
                    <tr className="row0">
                        <td className="column0 style1 s">Вид нотариального действия</td>
                        <td className="column1 style2 s style4" colSpan={3}>
                            Тариф
                        </td>
                        <td className="column4 style1 s">
                            Плата за оказание услуг правового и технического характера в 2023
                            году
                        </td>
                        <td className="column5 style1 s">
                            Плата за оказание услуг правового и технического характера за
                            нотариальные действия, совершаемые удаленно, и сделки,
                            удостоверяемые двумя и более нотариусами
                        </td>
                    </tr>
                    <tr className="row1">
                        <td className="column0 style2 s style4" colSpan={6}>
                            Удостоверение договоров, предмет которых подлежит оценке
                            <br />и предусмотрена обязательная нотариальная форма
                        </td>
                    </tr>
                    <tr className="row2">
                        <td className="column0 style5 s">Договор ренты</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                        </td>
                        <td className="column4 style7 n">6,700руб.</td>
                        <td className="column5 style7 n">9,743руб.</td>
                    </tr>
                    <tr className="row3">
                        <td className="column0 style5 s">
                            Соглашение о разделе совместно нажитого имущества супругов
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row4">
                        <td className="column0 style5 s">Договоры отчуждения недвижимости</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                        </td>
                        <td className="column4 style7 n">5,700руб.</td>
                        <td className="column5 style7 n">8,351руб.</td>
                    </tr>
                    <tr className="row5">
                        <td className="column0 style5 s">
                            Договоры дарения доли в уставном капитале Обществ с ограниченной
                            ответственностью
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                        </td>
                        <td className="column4 style7 n">15,000руб.</td>
                        <td className="column5 style7 n">23,662руб.</td>
                    </tr>
                    <tr className="row6">
                        <td className="column0 style5 s">
                            Договоры ипотеки долей в праве общей собственности на недвижимое
                            имущество (за исключением договоров ипотеки долей в общей
                            собственности на недвижимое имущество в обеспечение возврата кредита
                            (займа), предоставленного на приобретение или строительство или на
                            реструктуризацию кредита (займа), предоставленного на приобретение
                            или строительство)
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,3 % от суммы договора, но не более 3000 руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row7">
                        <td className="column0 style5 s">
                            Договоры ипотеки долей в общей собственности на недвижимое имущество
                            в обеспечение возврата кредита (займа), предоставленного на
                            приобретение или строительство или на реструктуризацию кредита
                            (займа), предоставленного на приобретение или строительство
                        </td>
                        <td className="column1 style8 n style4" colSpan={3}>
                            200руб.
                        </td>
                        <td className="column4 style7 n">8,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row8">
                        <td className="column0 style5 s">
                            Соглашение о предоставлении опциона на заключение договора с уплатой
                            опционной премии
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,5 % от суммы опционной премии
                        </td>
                        <td className="column4 style7 n">33,000руб.</td>
                        <td className="column5 style7 n">41,757руб.</td>
                    </tr>
                    <tr className="row9">
                        <td className="column0 style5 s">
                            Прочие договоры, предмет которых подлежит оценке и предусмотрена
                            обязательная нотариальная форма
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row10">
                        <td className="column0 style9 s">Договор конвертируемого займа</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                        </td>
                        <td className="column4 style7 n">33,000руб.</td>
                        <td className="column5 style7 n">41,757руб.</td>
                    </tr>
                    <tr className="row11">
                        <td className="column0 style10 s style4" colSpan={6}>
                            Удостоверение сделок по отчуждению недвижимого имущества, для
                            которых
                            <br />
                            не предусмотрена обязательная нотариальная форма
                        </td>
                    </tr>
                    <tr className="row12">
                        <td className="column0 style5 s">До 1 000 000 рублей</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            3000 рублей + 0,4 % от суммы сделки
                        </td>
                        <td className="column4 style11 n">12,000руб.</td>
                        <td className="column5 style11 n">15,311руб.</td>
                    </tr>
                    <tr className="row13">
                        <td className="column0 style5 s">От 1 000 001 до 10 000 000 руб.</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            7000 руб. + 0,2 % от суммы сделки, превышающей 1000000 руб.
                        </td>
                        <td className="column4 style11 n">12,000руб.</td>
                        <td className="column5 style11 n">15,311руб.</td>
                    </tr>
                    <tr className="row14">
                        <td className="column0 style5 s">Свыше 10 000 000 руб.</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            25000 руб. + 0,1 % от суммы сделки, превышающей 10000000 рублей, а в
                            случае отчуждения жилых помещений (квартир, комнат, жилых домов) и
                            земельных участков, занятых жилыми домами – не более 100000 руб.
                        </td>
                        <td className="column4 style7 n">12,000руб.</td>
                        <td className="column5 style7 n">15,311руб.</td>
                    </tr>
                    <tr className="row15">
                        <td className="column0 style12 s style4" colSpan={6}>
                            супругу, родителям, детям, внукам (при наличии подлинных документов,
                            подтверждающих родство, свидетельства о рождении/ браке)
                        </td>
                    </tr>
                    <tr className="row16">
                        <td className="column0 style5 s">
                            До 10 000 000 рублей включительно
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            3000 рублей + 0,2% от суммы сделки
                        </td>
                        <td className="column4 style11 n">12,000руб.</td>
                        <td className="column5 style11 n">15,311руб.</td>
                    </tr>
                    <tr className="row17">
                        <td className="column0 style5 s">Свыше 10 000 000 рублей</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            23000 рублей + 0,1% суммы сделки, превышающей 10000000 рублей, но не
                            более 50 000 руб.
                        </td>
                        <td className="column4 style7 n">12,000руб.</td>
                        <td className="column5 style7 n">15,311руб.</td>
                    </tr>
                    <tr className="row18">
                        <td className="column0 style2 s style4" colSpan={6}>
                            Удостоверение прочих сделок, предмет которых подлежит оценке и<br />
                            не предусмотрена обязательная нотариальная форма
                        </td>
                    </tr>
                    <tr className="row19">
                        <td className="column0 style5 s">До 1 000 000 рублей</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            2000 руб. + 0,3 % от суммы сделки.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row20">
                        <td className="column0 style5 s">От 1 000 001 до 10 000 000 руб.</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row21">
                        <td className="column0 style13 s">Свыше 10 000 000 руб.</td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но
                            не более 500000 руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row22">
                        <td className="column0 style5 s">
                            Договоры ипотеки недвижимого имущества в обеспечение возврата
                            кредита (займа), предоставленного на приобретение или строительство
                            или на реструктуризацию кредита (займа), предоставленного на
                            приобретение или строительство
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            2000 руб. + 0,3 % от суммы сделки
                            <br />
                            5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                            <br />
                            23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но
                            не более 500000 руб.
                        </td>
                        <td className="column4 style7 n">8,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row23">
                        <td className="column0 style5 s">
                            Удостоверение медиативных соглашений, если предмет подлежит оценке,
                            в зависимости от ее величины:
                            <br />
                            <br />
                            До 1 000 000 рублей
                            <br />
                            <br />
                            <br />
                            От 1 000 001 до 10 000 000 рублей
                            <br />
                            <br />
                            <br />
                            Свыше 10 000 000 рублей
                        </td>
                        <td className="column1 style10 s style4" colSpan={3}>
                            2000 руб. + 0,3 % от суммы сделки.
                            <br />
                            <br />
                            5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                            <br />
                            <br />
                            23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но
                            не более 500000 руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row24">
                        <td className="column0 style5 s">
                            Удостоверение договоров поручительства, в зависимости от величины
                            оценки:
                            <br />
                            <br />
                            До 1 000 000 рублей
                            <br />
                            <br />
                            <br />
                            От 1 000 001 до 10 000 000 рублей
                            <br />
                            <br />
                            <br />
                            Свыше 10 000 000 рублей
                        </td>
                        <td className="column1 style10 s style4" colSpan={3}>
                            2000 руб. + 0,3 % от суммы сделки.
                            <br />
                            <br />
                            5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                            <br />
                            <br />
                            23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но
                            не более 500000 руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row25">
                        <td className="column0 style5 s">
                            Удостоверение независимой банковской гарантии, в зависимости от
                            величины
                            <br />
                            <br />
                            До 1 000 000 рублей
                            <br />
                            <br />
                            <br />
                            От 1 000 001 до 10 000 000 рублей
                            <br />
                            <br />
                            <br />
                            Свыше 10 000 000 рублей:
                        </td>
                        <td className="column1 style10 s style4" colSpan={3}>
                            2000 руб. + 0,3 % от суммы сделки.
                            <br />
                            <br />
                            5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                            <br />
                            <br />
                            23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но
                            не более 500000 руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row26">
                        <td className="column0 style10 s style4" colSpan={6}>
                            Удостоверение договоров купли-продажи или залога, предметом
                            <br />
                            которых являются доля или часть доли в уставном капитале Обществ с
                            ограниченной ответственностью, а также удостоверение акцепта
                            безотзывной оферты о продаже доли в уставном капитале общества.
                        </td>
                    </tr>
                    <tr className="row27">
                        <td className="column0 style5 s">
                            Удостоверение договора купли-продажи (до 1 000 000 рублей)
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,5% суммы договора, но не менее 1500 руб.
                        </td>
                        <td className="column4 style14 s">
                            Физ. лица
                            <br />
                            15000 руб.
                            <br />
                            Юр. лица
                            <br />
                            18000 руб.
                            <br />
                            Иностранные юр. лица
                            <br />
                            33000 руб.
                        </td>
                        <td className="column5 style14 s">
                            Физ. лица 23662 руб.
                            <br />
                            Юр. лица 23662 руб.
                            <br />
                            Иностранные юр. лица
                            <br />
                            41757 руб.
                        </td>
                    </tr>
                    <tr className="row28">
                        <td className="column0 style5 s">
                            Удостоверение договора купли-продажи (от 1 000 001 до 10 000 000
                            рублей).
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            5000 руб. + 0,3% суммы договора, превышающей 1000000 руб.
                        </td>
                        <td className="column4 style14 s">
                            Физ. лица
                            <br />
                            15000 руб.
                            <br />
                            Юр. лица
                            <br />
                            18000 руб.
                            <br />
                            Иностранные юр. лица
                            <br />
                            33000 руб.
                        </td>
                        <td className="column5 style14 s">
                            Физ. лица 23662 руб.
                            <br />
                            Юр. лица 23662 руб.
                            <br />
                            Иностранные юр. лица
                            <br />
                            41757 руб.
                        </td>
                    </tr>
                    <tr className="row29">
                        <td className="column0 style5 s">
                            Удостоверение договора купли-продажи (свыше 10 000 001 рублей)
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            32000 руб. + 0,15% суммы договора, превышающей 10000000 руб., но не
                            более 150000 руб.
                        </td>
                        <td className="column4 style14 s">
                            Физ. лица
                            <br />
                            15000 руб.
                            <br />
                            Юр. лица
                            <br />
                            18000 руб.
                            <br />
                            Иностранные юр. лица
                            <br />
                            33000 руб.
                        </td>
                        <td className="column5 style14 s">
                            Физ. лица 23662 руб.
                            <br />
                            Юр. лица 23662 руб.
                            <br />
                            Иностранные юр. лица
                            <br />
                            41757 руб.
                        </td>
                    </tr>
                    <tr className="row30">
                        <td className="column0 style5 s">
                            Удостоверение договора залога (до 1 000 000 рублей)
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            0,5% суммы договора, но не менее 1500 руб.
                        </td>
                        <td className="column4 style15 s">
                            Физ. лица 20000 руб.
                            <br />
                            Юр. лица 24000 руб.
                            <br />
                            Иностранные юр. лица 33000 руб.
                        </td>
                        <td className="column5 style15 s">
                            Физ. лица 30622 руб.
                            <br />
                            Юр. лица 30622 руб.
                            <br />
                            Иностранные юр. лица 41757 руб.
                        </td>
                    </tr>
                    <tr className="row31">
                        <td className="column0 style5 s">
                            Удостоверение договора залога (от 1 000 001 до 10 000 000 рублей).
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            5000 руб. + 0,3% суммы договора, превышающей 1000000 руб.
                        </td>
                        <td className="column4 style15 s">
                            Физ. лица 20000 руб.
                            <br />
                            Юр. лица 24000 руб.
                            <br />
                            Иностранные юр. лица 33000 руб.
                        </td>
                        <td className="column5 style15 s">
                            Физ. лица 30622 руб.
                            <br />
                            Юр. лица 30622 руб.
                            <br />
                            Иностранные юр. лица 41757 руб.
                        </td>
                    </tr>
                    <tr className="row32">
                        <td className="column0 style5 s">
                            Удостоверение договора залога (свыше 10 000 001 рублей)
                        </td>
                        <td className="column1 style6 s style4" colSpan={3}>
                            32000 руб. + 0,15% суммы договора, превышающей 10000000 руб., но не
                            более 150000 руб.
                        </td>
                        <td className="column4 style15 s">
                            Физ. лица 20000 руб.
                            <br />
                            Юр. лица 24000 руб.
                            <br />
                            Иностранные юр. лица 33000 руб.
                        </td>
                        <td className="column5 style15 s">
                            Физ. лица 30622 руб.
                            <br />
                            Юр. лица 30622 руб.
                            <br />
                            Иностранные юр. лица 41757 руб.
                        </td>
                    </tr>
                    <tr className="row33">
                        <td className="column0 style5 s">
                            Удостоверение акцепта безотзывной оферты о продаже доли в уставном
                            капитале общества до 1 000 000 руб.
                        </td>
                        <td className="column1 style12 s style4" colSpan={3}>
                            0,5 % суммы договора, но не менее 1500 руб.
                        </td>
                        <td className="column4 style7 n">4,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row34">
                        <td className="column0 style5 s">
                            Удостоверение акцепта безотзывной оферты о продаже доли в уставном
                            капитале общества от 1 000 001 руб. до 10 000 000 руб. включительно
                        </td>
                        <td className="column1 style12 s style4" colSpan={3}>
                            5000 руб. + 0,3 % суммы договора, превышающей <br />
                            1000000 руб.
                        </td>
                        <td className="column4 style7 n">4,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row35">
                        <td className="column0 style5 s">
                            Удостоверение акцепта безотзывной оферты о продаже доли в уставном
                            капитале общества свыше 10 000 001 руб.
                        </td>
                        <td className="column1 style12 s style4" colSpan={3}>
                            32000 руб. + 0,15 % суммы договора, превышающей <br />
                            10000000 руб., но не более 150000 руб.
                        </td>
                        <td className="column4 style7 n">4,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row36">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Иной договор (соглашение), в том числе медиативное соглашение,
                            предмет которого не подлежит оценке
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row37">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Предварительный договор
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row38">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Соглашение об определении долей
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row39">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Соглашение о порядке пользования помещением
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style11 n">13,919руб.</td>
                    </tr>
                    <tr className="row40">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Брачный договор
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style11 n">14,000руб.</td>
                        <td className="column5 style11 n">18,095руб.</td>
                    </tr>
                    <tr className="row41">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Соглашение об уплате алиментов
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            250руб.
                        </td>
                        <td className="column4 style14 s">5500 руб. -7550 руб.</td>
                        <td className="column5 style17 n">11,135руб.</td>
                    </tr>
                    <tr className="row42">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Удостоверение соглашения по оформлению в долевую собственность
                            родителей и детей жилого помещения, приобретенного с использованием
                            средств материнского (семейного) капитала
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">5,500руб.</td>
                        <td className="column5 style7 n">6,960руб.</td>
                    </tr>
                    <tr className="row43">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Удостоверение наследственного договора.
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row44">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Удостоверение соглашения об управлении хозяйственным партнерством
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">33,000руб.</td>
                        <td className="column5 style7 n">41,757руб.</td>
                    </tr>
                    <tr className="row45">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Соглашение об изменении или о расторжении нотариально
                            удостоверенного договора (соглашения), если предмет соглашения не
                            подлежит оценке
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column4 style14 s">
                            Плата равна установленной для удостоверения изменяемого или
                            расторгаемого договора
                        </td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row46">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Удостоверение договора инвестиционного товарищества
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">33,000руб.</td>
                        <td className="column5 style7 n">41,757руб.</td>
                    </tr>
                    <tr className="row47">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Удостоверение заявления участника о выходе из общества с
                            ограниченной ответственностью, не являющегося кредитной организацией
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">4,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row48">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Удостоверение требования участника общества о приобретении доли
                            обществом
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">1,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row49">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Удостоверение оферты о продаже доли или части доли в уставном
                            капитале общества
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style14 s">
                            Физ. лица 2000 руб.
                            <br />
                            Юр. лица 2500 руб.
                            <br />
                            Иностранные юр. лица 3500 руб.
                        </td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row50">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Удостоверение безотзывной оферты во исполнение опциона на заключение
                            договора
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">4,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row51">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Соглашение о предоставлении опциона на заключение договора
                            (безвозмездно), удостоверение опционного договора
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">33,000руб.</td>
                        <td className="column5 style7 n">41,757руб.</td>
                    </tr>
                    <tr className="row52">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Удостоверение факта возникновения права собственности на объекты
                            недвижимого имущества в силу приобретательной давности
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            1,500руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row53">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Согласие на выезд несовершеннолетних детей
                        </td>
                        <td className="column2 style12 s style4" colSpan={2}>
                            100 руб.
                            <br />
                            200 руб. от двоих родителей
                        </td>
                        <td className="column4 style7 n">1,500руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row54">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Согласие супруга на совершение сделки другим супругом
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">1,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row55">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Прочие согласия (на приватизацию и др.)
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">1,500руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row56">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Отказ сособственника от преимущественного права покупки продаваемой
                            доли в недвижимом имуществе (в т.ч. при отчуждении доли по договору
                            мены – ст. 250 ГК РФ)
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">1,500руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row57">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Иные односторонние сделки и юридически значимые волеизъявления
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">1,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row58">
                        <td className="column0 style6 s style4" colSpan={2}>
                            Иные односторонние сделки с участием юридических лиц
                        </td>
                        <td className="column2 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column4 style7 n">3,500руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row59">
                        <td className="column0 style2 s style4" colSpan={6}>
                            Удостоверение договоров дарения, за исключением договоров дарения
                            недвижимого имущества и долей уставного капитала в обществе с
                            ограниченной ответственностью.
                        </td>
                    </tr>
                    <tr className="row60">
                        <td className="column0 style6 s style4" colSpan={3}>
                            - детям, в том числе усыновленным, супругу, родителям, полнородным
                            братьям и сестрам
                        </td>
                        <td className="column3 style5 s">
                            0,3 % от суммы договора, но не менее 200 руб.
                        </td>
                        <td className="column4 style7 n">11,000руб.</td>
                        <td className="column5 style7 n">13,919руб.</td>
                    </tr>
                    <tr className="row61">
                        <td className="column0 style6 s style4" colSpan={3}>
                            - другим лицам
                        </td>
                        <td className="column3 style14 s">
                            1% от суммы договора, но не менее 300 руб.
                        </td>
                        <td className="column4 style11 n">11,000руб.</td>
                        <td className="column5 style11 n">13,919руб.</td>
                    </tr>
                    <tr className="row62">
                        <td className="column0 style10 s style4" colSpan={6}>
                            Доверенности
                        </td>
                    </tr>
                    <tr className="row63">
                        <td className="column0 style6 s style4" colSpan={3}>
                            Доверенности на получение пенсии и социальных выплат, связанных с
                            инвалидностью
                        </td>
                        <td className="column3 style11 n">200руб.</td>
                        <td className="column4 style7 n">1,000руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row64">
                        <td className="column0 style6 s style4" colSpan={3}>
                            Доверенности на ведение дела в суде, подачу и (или) получение
                            документов от имени физических лиц
                        </td>
                        <td className="column3 style11 n">200руб.</td>
                        <td className="column4 style7 n">1,500руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row65">
                        <td className="column0 style6 s style4" colSpan={3}>
                            Доверенности на распоряжение банковским счетом (вкладом) от имени
                            физических лиц
                        </td>
                        <td className="column3 style11 n">500руб.</td>
                        <td className="column4 style7 n">1,500руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row66">
                        <td className="column0 style6 s style4" colSpan={3}>
                            Доверенности на право пользования и (или) распоряжения имуществом
                            (за исключением доверенности на распоряжение банковским счетом
                            (вкладом)) от имени физических лиц
                        </td>
                        <td className="column3 style11 n">500руб.</td>
                        <td className="column4 style7 n">1,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row67">
                        <td className="column0 style6 s style4" colSpan={3}>
                            Прочие доверенности от имени физических лиц
                        </td>
                        <td className="column3 style11 n">200руб.</td>
                        <td className="column4 style11 n">1,800руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row68">
                        <td className="column0 style6 s style4" colSpan={3}>
                            Доверенности от имени юридических лиц на право пользования и (или)
                            распоряжения имуществом
                        </td>
                        <td className="column3 style11 n">500руб.</td>
                        <td className="column4 style7 n">2,700руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row69">
                        <td className="column0 style6 s style4" colSpan={3}>
                            Прочие доверенности от имени юридических лиц
                        </td>
                        <td className="column3 style11 n">200руб.</td>
                        <td className="column4 style7 n">2,700руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row70">
                        <td className="column0 style6 s style4" colSpan={3}>
                            Доверенности в порядке передоверия
                        </td>
                        <td className="column3 style11 n">200руб.</td>
                        <td className="column4 style7 n">2,700руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row71">
                        <td className="column0 style6 s style4" colSpan={3}>
                            Удостоверение распоряжения об отмене доверенности
                        </td>
                        <td className="column3 style11 n">500руб.</td>
                        <td className="column4 style7 n">1,200руб.</td>
                        <td className="column5 style16 null" />
                    </tr>
                    <tr className="row72">
                        <td className="column0 style18 null" />
                        <td className="column1">&nbsp;</td>
                        <td className="column2">&nbsp;</td>
                        <td className="column3">&nbsp;</td>
                        <td className="column4">&nbsp;</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row73">
                        <td className="column0 style2 s style4" colSpan={5}>
                            Завещания
                        </td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row74">
                        <td className="column0 style5 s">Завещание</td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">2,000руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row75">
                        <td className="column0 style5 s">Принятие закрытого завещания</td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">2,700руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row76">
                        <td className="column0 style5 s">
                            Вскрытие конверта с закрытым завещанием и оглашение закрытого
                            завещания
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            300руб.
                        </td>
                        <td className="column3 style7 n">2,700руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row77">
                        <td className="column0 style5 s">Распоряжение об отмене завещания</td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column3 style7 n">500руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row78">
                        <td className="column0 style5 s">
                            Завещание, условия которого предусматривают создание наследственного
                            фонда
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">16,500руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row79">
                        <td className="column0 style5 s">Совместное завещание супругов</td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column3 style7 n">3,600руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row80">
                        <td className="column0 style2 s style4" colSpan={5}>
                            Копии документов.
                        </td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row81">
                        <td className="column0 style5 s">
                            За свидетельствование верности копий документов, выписок из
                            документа (за страницу копии документов или выписки из них)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            10руб.
                        </td>
                        <td className="column3 style15 s">80 руб. за каждую страницу</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row82">
                        <td className="column0 style5 s">
                            За удостоверение учредительных документов организации (копий
                            учредительных документов в соответствии со ст. 52 ГК РФ)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            500руб.
                        </td>
                        <td className="column3 style15 s">80 руб. за каждую страницу</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row83">
                        <td className="column0 style2 s style4" colSpan={5}>
                            Свидетельствование подлинности подписи
                        </td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row84">
                        <td className="column0 style9 s">
                            - физических лиц, в том числе на заявлениях в наследственное дело,
                            необходимых для выдачи свидетельств о праве на наследство (за
                            исключением свидетельствования подлинности подписи на заявлении о
                            регистрации в качестве ИП и об осуществлении государственного
                            кадастрового учета и (или) государственной регистрации прав)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">1,100руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row85">
                        <td className="column0 style5 s">- переводчика на переводах</td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">500руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row86">
                        <td className="column0 style5 s">
                            - на банковских карточках физ. лиц и ИП
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column3 style7 n">1,100руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row87">
                        <td className="column0 style5 s">
                            на банковских карточках от имени юридического лица
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            200 руб.
                            <br />
                            &nbsp;(за подпись)
                        </td>
                        <td className="column3 style15 s">
                            1600 руб. <br />
                            (за документ)
                        </td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row88">
                        <td className="column0 style5 s">
                            на заявлениях о регистрации в МИФНС физ. лиц и ИП
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column3 style17 n">1,600руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row89">
                        <td className="column0 style9 s">
                            на заявлениях о государственной регистрации юридического лица при
                            создании; <br />
                            <br />
                            за подпись последнего из заявителей (единственного заявителя)
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            200 руб.
                            <br />
                            <br />
                            200 руб.
                        </td>
                        <td className="column3 style14 s">
                            1600 руб.
                            <br />
                            <br />
                            3300 руб.
                        </td>
                        <td className="column4 style19 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row90">
                        <td className="column0 style9 s">
                            на заявлениях о государственной регистрации физического лица в
                            качестве индивидуального предпринимателя
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style11 n">3,300руб.</td>
                        <td className="column4 style19 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row91">
                        <td className="column0 style5 s">
                            на заявлениях в МИФНС от имени юридического лица (за каждую подпись)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column3 style11 n">1,600руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row92">
                        <td className="column0 style9 s">
                            на прочих заявлениях от имени юридического лица (за исключением
                            свидетельствования подлинности подписи на заявлении об осуществлении
                            государственного кадастрового учета и (или) государственной
                            регистрации прав)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">1,600руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row93">
                        <td className="column0 style5 s">
                            - на заявлениях от имени физических и юридических лиц об
                            осуществлении государственного кадастрового учета и (или)
                            государственной регистрации прав
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">2,200руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row94">
                        <td className="column0 style10 s style4" colSpan={5}>
                            Прочие нотариальные действия
                        </td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row95">
                        <td className="column0 style20 s style21" colSpan={2}>
                            Свидетельство об удостоверении решения коллегиального органа
                            управления юридического лица, для которого предусмотрена
                            обязательная нотариальная форма:
                            <br />
                            <br />- решение об увеличении уставного капитала
                        </td>
                        <td className="column2 style22 n">100руб.</td>
                        <td className="column3 style23 s">
                            подготовка к присутствию на заседании органа управления юридического
                            лица 5500 руб.,
                            <br />
                            за каждый час присутствия нотариуса на заседании соответствующего
                            органа 3600 руб.,
                            <br />
                            выдача свидетельства 2200 руб.
                        </td>
                        <td className="column4 style24 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row96">
                        <td className="column0 style25 s style26" colSpan={2}>
                            Свидетельство об удостоверении решения коллегиального органа
                            управления юридического лица, для которого не предусмотрена
                            обязательная нотариальная форма
                        </td>
                        <td className="column2 style27 s">
                            3000 руб. за каждый час присутствия нотариуса на заседании
                            соответствующего органа
                        </td>
                        <td className="column3 style27 s">
                            подготовка к присутствию на заседании органа управления юридического
                            лица 5500 руб.,
                            <br />
                            за каждый час присутствия нотариуса на заседании соответствующего
                            органа 3600 руб.,
                            <br />
                            выдача свидетельства 2200 руб.
                        </td>
                        <td className="column4 style28 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row97">
                        <td className="column0 style25 s style26" colSpan={2}>
                            Выдача свидетельства об удостоверении факта принятия решения
                            единственным участником юридического лица, для которого
                            предусмотрена обязательная нотариальная форма (об увеличении
                            уставного капитала)
                        </td>
                        <td className="column2 style29 n">100руб.</td>
                        <td className="column3 style30 n">5,500руб.</td>
                        <td className="column4 style28 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row98">
                        <td className="column0 style31 s style26" colSpan={2}>
                            Выдача свидетельства об удостоверении факта принятия решения
                            единственным участником юридического лица, для которого не
                            предусмотрена обязательная нотариальная форма
                        </td>
                        <td className="column2 style29 n">100руб.</td>
                        <td className="column3 style30 n">5,500руб.</td>
                        <td className="column4 style28 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row99">
                        <td className="column0 style32 s">
                            Принятие в депозит нотариуса денежных сумм или ценных бумаг (если
                            для такого принятия установлена обязательная нотариальная форма), за
                            исключением принятия на депонирование нотариуса денежных средств в
                            целях исполнения обязательств сторон по сделке, до пяти кредиторов.
                        </td>
                        <td className="column1 style33 s style34" colSpan={2}>
                            0,5% от принятой денежной суммы или рыночной стоимости ценных бумаг,
                            но не менее 20 рублей и не более 20000 руб.
                        </td>
                        <td className="column3 style35 s">
                            6600 рублей +1100 рублей за каждого последующего кредитора, начиная
                            с шестого.
                        </td>
                        <td className="column4 style36 s">8351 руб.+1392 руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row100">
                        <td className="column0 style5 s">
                            Принятие в депозит нотариуса денежных средств или ценных бумаг (не
                            установлена обязательная нотариальная форма), за исключением случаев
                            принятия на депонирование денежных средств в целях исполнения
                            обязательств по сделке, до пяти кредиторов
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            0,5 % от принятой денежной суммы или рыночной стоимости ценных
                            бумаг, но не менее 1000 руб.
                        </td>
                        <td className="column3 style15 s">
                            6600 рублей +1100 рублей за каждого последующего кредитора, начиная
                            с шестого.
                        </td>
                        <td className="column4 style15 s">8351 руб.+1392 руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row101">
                        <td className="column0 style5 s">
                            Принятие в депозит нотариуса входящих в состав наследства наличных
                            денежных средств.
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            0,5% от принятой денежной суммы, но не менее 1000 руб.
                        </td>
                        <td className="column3 style7 n">6,600руб.</td>
                        <td className="column4 style7 n">8,351руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row102">
                        <td className="column0 style5 s">
                            Принятие на депонирование нотариусом:
                            <br />
                            <br />
                            <br />
                            - безналичных денежных средств в целях исполнения обязательств
                            сторон по нотариально удостоверенной сделке
                            <br />
                            <br />- наличных денежных средств в целях исполнения обязательств
                            сторон по нотариально удостоверенной сделке
                        </td>
                        <td className="column1 style37 n style4" colSpan={2}>
                            1,500руб.
                        </td>
                        <td className="column3 style7 n">1,100руб.</td>
                        <td className="column4 style7 n">1,392руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row103">
                        <td className="column0 style5 s">
                            Принятие нотариусом на депонирование в иных случаях :<br />
                            <br />
                            -безналичных денежных средств, наличных денег, ценных бумаг;
                            <br />
                            <br />
                            <br />
                            &nbsp;-движимых вещей
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            0,5% принятой денежной суммы, рыночной стоимости ценных бумаг или
                            заявленной депонентом стоимости имущества, но не менее 1000 руб.
                        </td>
                        <td className="column3 style15 s">
                            6600 руб.
                            <br />
                            <br />
                            <br />
                            16500 руб.
                        </td>
                        <td className="column4 style7 n">8,351руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row104">
                        <td className="column0 style5 s">
                            Принятие в депозит нотариуса в порядке ст.327 ГК РФ денежных сумм в
                            целях исполнения обязательств по ранее удостоверенной нотариально
                            сделке (например, по нотариально удостоверенному договору займа,
                            кредитор по которому уклоняется от принятия исполнения )
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            1,500руб.
                        </td>
                        <td className="column3 style7 n">6,600руб.</td>
                        <td className="column4 style7 n">8,351руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row105">
                        <td className="column0 style5 s">За совершение морского протеста</td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            30 000 руб.
                        </td>
                        <td className="column3 style7 n">14,000руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row106">
                        <td className="column0 style5 s">
                            - За совершение исполнительной надписи
                        </td>
                        <td className="column1 style38 s style4" colSpan={2}>
                            - об обращении взыскания на заложенное имущество: 0,5% начальной
                            продажной цены или стоимости заложенного имущества, определенной в
                            отчете о его оценке, но не менее 1500 руб. и не более
                            <br />
                            300 000 руб. <br />
                            - о взыскании денежных сумм в размере до 3000 руб. включительно или
                            об истребовании имущества стоимостью до 3000 руб. включительно: 300
                            руб.
                            <br />
                            - о взыскании денежных сумм в размере 3001 руб. до 10000 руб.
                            включительно или об истребовании имущества стоимостью от 3001 руб.
                            до 10000 руб. включительно: 500 руб.
                            <br />
                            - о взыскании денежных сумм в размере 10001 руб. до 200000 руб.
                            включительно или об истребовании имущества стоимостью от 10001 руб.
                            до 200000 руб. включительно: 1000 руб.
                            <br />- о взыскании денежных сумм свыше 200000 руб. или об
                            истребовании имущества стоимостью свыше 200000 руб.: 0.5%
                            взыскиваемой суммы или стоимости истребуемого имущества, но не менее
                            1500 руб..
                        </td>
                        <td className="column3 style15 s">
                            3300 руб.
                            <br />
                            &nbsp;за исключением совершения исполнительной надписи об обращении
                            на заложенное имущество
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            5500 руб. <br />
                            за исполнительную надпись об обращении взыскания на заложенное
                            имущество
                        </td>
                        <td className="column4 style15 s">
                            4176 руб. за исключением совершения исполнительной надписи об
                            обращении взыскания на заложенное имущество, при этом при совершении
                            исполнительной надписи на основании кредитного договора, договора
                            поручительства, предусматривающего солидарную ответственность
                            поручителя по кредитному договору, или договора об оказании услуг
                            связи в следующем размере: <br />
                            до 10000 руб. включительно-243 руб.;
                            <br />
                            от 10000 руб. до 200000 руб.- 485 руб.;
                            <br />
                            от 200000 руб. до 500000 руб.- 728 руб.;
                            <br />
                            от 500000 до 1000000 руб.- 2063 руб.;
                            <br />
                            от 1000000 руб.- 2913 руб.
                        </td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row107">
                        <td className="column0 style5 s">За совершение протеста векселя</td>
                        <td className="column1 style6 s style4" colSpan={2}>
                            1% неоплаченной суммы, но не более 20 000 руб.
                        </td>
                        <td className="column3 style7 n">22,000руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row108">
                        <td className="column0 style5 s">
                            Предъявление чека к платежу и удостоверение неоплаты чека
                        </td>
                        <td className="column1 style6 s style4" colSpan={2}>
                            1% неоплаченной суммы, но не более 20 000 руб.
                        </td>
                        <td className="column3 style7 n">14,300руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row109">
                        <td className="column0 style5 s">За выдачу дубликатов документов</td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">5,400руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row110">
                        <td className="column0 style5 s">
                            Передача заявлений или иных документов с использованием почты или
                            иных средств связи (за исключением передачи документов при обращении
                            удаленно физических и юридических лиц с заявлением о передаче
                            электронных документов другим физическим и юридическим лицам)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">2,400руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row111">
                        <td className="column0 style5 s">
                            Передача электронных документов при обращении удаленно физических и
                            юридических лиц с заявлением о передаче электронных документов
                            другим физическим и юридическим лицам
                        </td>
                        <td className="column1 style39 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">0руб.</td>
                        <td className="column4 style7 n">139руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row112">
                        <td className="column0 style5 s">
                            Передача заявлений или иных документов лично нотариусом (или
                            работником нотариуса в случаях, установленных законодательством) по
                            месту нахождения получателя заявления или документа
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">3,300руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row113">
                        <td className="column0 style5 s">
                            Свидетельствование верности перевода документа с одного языка на
                            другой
                        </td>
                        <td className="column1 style40 s style4" colSpan={2}>
                            100 руб. за одну страницу перевода документа
                        </td>
                        <td className="column3 style7 n">1,100руб.</td>
                        <td className="column4 style7 n">1,392руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row114">
                        <td className="column0 style5 s">
                            При выдаче свидетельств (о передаче заявлений, об удостоверении
                            факта нахождения в живых, нахождения гражданина в определенном
                            месте, удостоверение факта времени предъявления документов)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">2,200руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row115">
                        <td className="column0 style5 s">
                            Обеспечение доказательств (допрос свидетеля, осмотр письменных и
                            вещественных доказательств, в т.ч. в сети интернет, назначение
                            экспертизы)
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            3 000 руб.
                        </td>
                        <td className="column3 style15 s">
                            3300 руб. за каждую страницу протокола +<br />
                            120 руб. за каждую страницу приложения к протоколу
                        </td>
                        <td className="column4 style15 s">4176 руб.+153 руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row116">
                        <td className="column0 style5 s">
                            Регистрация уведомления о залоге движимого имущества
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            600руб.
                        </td>
                        <td className="column3 style15 s">
                            220 руб. за каждую страницу уведомления, предоставленного в бумажном
                            виде
                        </td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row117">
                        <td className="column0 style5 s">
                            Выдача выписки из реестра уведомлений о залоге движимого имущества
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            40 руб. за каждую страницу выписки в пределах первой - десятой
                            страниц включительно, 20 рублей за каждую страницу выписки начиная с
                            одиннадцатой страницы
                        </td>
                        <td className="column3 style15 s">
                            50 руб. за каждую страницу документа
                        </td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row118">
                        <td className="column0 style5 s">
                            Выдача выписки из реестра уведомлений о залоге движимого имущества в
                            электронной форме
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column3 style7 n">0руб.</td>
                        <td className="column4 style15 s">
                            70 руб. за каждую страницу выписки
                        </td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row119">
                        <td className="column0 style9 s">
                            Удостоверение равнозначности документа на бумажном носителе
                            электронному документу (за каждую страницу документа на бумажном
                            носителе)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            50руб.
                        </td>
                        <td className="column3 style7 n">120руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row120">
                        <td className="column0 style9 s">
                            Удостоверение равнозначности электронного документа документу на
                            бумажном носителе (за каждую страницу документа на бумажном
                            носителе)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            50руб.
                        </td>
                        <td className="column3 style41 s">
                            120 руб. <br />
                            при представлении документа объемом до 14 страниц включительно,
                            <br />
                            90 руб. <br />
                            при представлении документа объемом свыше 14 страниц
                        </td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row121">
                        <td className="column0 style9 s">
                            Удостоверение равнозначности электронного документа, изготовленного
                            нотариусом в ином формате, электронному документу, предоставленному
                            нотариусу посредством изменения его формата (конвертации), за 1
                            мегабайт
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            60руб.
                        </td>
                        <td className="column3 style7 n">0руб.</td>
                        <td className="column4 style7 n">70руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row122">
                        <td className="column0 style5 s">
                            За представление документов на государственную регистрацию
                            юридических лиц и индивидуальных предпринимателей в электронном виде
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            1,000руб.
                        </td>
                        <td className="column3 style7 n">1,200руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row123">
                        <td className="column0 style5 s">
                            Передача сведений, которые содержатся в заявлениях физ. лиц и юр.
                            лиц в Единый федеральный реестр сведений о банкротстве, а также в
                            Единый федеральный реестр сведений о фактах деятельности юридических
                            лиц.
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            600руб.
                        </td>
                        <td className="column3 style7 n">1,600руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row124">
                        <td className="column0 style5 s">
                            Хранение документов, за каждый день хранения каждых полных или
                            неполных 250 листов документов
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            20 руб. за каждый день хранения
                        </td>
                        <td className="column3 style14 s">
                            80 руб. за каждый день хранения документа
                        </td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row125">
                        <td className="column0 style5 s">
                            Принятие на хранение электронного документа (пакета электронных
                            документов) объемом до 1 мегабайта включительно
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            5 руб. за полный (неполный) год хранения, но не менее 200 руб.
                        </td>
                        <td className="column3 style7 n">0руб.</td>
                        <td className="column4 style15 s">139 рублей</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row126">
                        <td className="column0 style5 s">
                            Выдача принятого на хранение электронного документа (пакета
                            электронных документов)
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column3 style7 n">0руб.</td>
                        <td className="column4 style7 n">139руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row127">
                        <td className="column0 style5 s">
                            За выдачу свидетельства о праве собственности на долю в общем
                            имуществе пережившему супругу на движимое имущество.
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column3 style7 n">2,200руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row128">
                        <td className="column0 style5 s">
                            За выдачу свидетельства о праве собственности на долю в общем
                            имуществе пережившему супругу на вклады, акции, иные ценные бумаги.
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column3 style7 n">1,600руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row129">
                        <td className="column0 style5 s">
                            За выдачу свидетельства о праве собственности на долю в общем
                            имуществе пережившего супруга на недвижимое имущество
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            200руб.
                        </td>
                        <td className="column3 style7 n">3,300руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row130">
                        <td className="column0 style5 s">
                            За выдачу свидетельства о праве на наследство по закону и по
                            завещанию.
                        </td>
                        <td className="column1 style6 s style4" colSpan={2}>
                            детям, в том числе усыновленным, супругу, родителям, полнородным
                            братьям и сестрам наследодателя - 0,3 % стоимости наследуемого
                            имущества, но не более 100000 руб.;
                            <br />
                            <br />
                            другим наследникам - 0,6 % стоимости наследуемого имущества, но не
                            более 1000 000 руб.;
                        </td>
                        <td className="column3 style15 s">
                            на вклады: 500-2200 руб.;
                            <br />
                            на иное имущество, помимо недвижимого (с каждого наследника за
                            каждый объект): 2800руб.;
                            <br />
                            на недвижимое имущество 6700 руб. (с каждого наследника за каждый
                            объект)
                        </td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row131">
                        <td className="column0 style5 s">
                            За выдачу свидетельства о праве на наследство по завещанию,
                            предусматривающему создание наследственного фонда
                        </td>
                        <td className="column1 style6 s style4" colSpan={2}>
                            детям, в том числе усыновленным, супругу, родителям, полнородным
                            братьям и сестрам наследодателя - 0,3 % стоимости наследуемого
                            имущества, но не более 100 000 руб;
                            <br />
                            <br />
                            другим наследникам - 0,6 % стоимости наследуемого имущества, но не
                            более 1 000 000 руб
                        </td>
                        <td className="column3 style7 n">11,000руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row132">
                        <td className="column0 style5 s">
                            За принятие мер по охране наследства, кроме удостоверения договора
                            доверительного управления наследственным имуществом (опись
                            наследственного имущества)
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            600 руб./ (0 руб.)
                        </td>
                        <td className="column3 style15 s">
                            5500 руб. за каждый час, затраченный на принятие мер по охране
                            наследства
                        </td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row133">
                        <td className="column0 style5 s">
                            Учреждение доверительного управления наследственным имуществом
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            600руб.
                        </td>
                        <td className="column3 style7 n">33,000руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row134">
                        <td className="column0 style5 s">
                            Передача нотариусом на хранение входящего в состав наследства
                            имущества, не требующего управления, наследникам либо иным лицам
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">5,500руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row135">
                        <td className="column0 style5 s">
                            Передача входящих в состав наследства валютных ценностей,
                            драгоценных металлов и камней, изделий из них и не требующих
                            управления ценных бумаг банку на хранение
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">11,000руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row136">
                        <td className="column0 style5 s">
                            Уведомление залогодателя (должника) об исполнении обязательства,
                            обеспеченного залогом
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">1,800руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row137">
                        <td className="column0 style5 s">
                            За выдачу свидетельства об удостоверении тождественности
                            собственноручной подписи инвалида по зрению с факсимильным
                            воспроизведением его собственноручной подписи
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">0руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row138">
                        <td className="column0 style5 s">
                            Внесение сведений в реестр списков участников обществ с ограниченной
                            ответственностью ЕИС нотариата
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            600руб.
                        </td>
                        <td className="column3 style7 n">1,100руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row139">
                        <td className="column0 style5 s">
                            Выдача выписки из реестра списков участников обществ с ограниченной
                            ответственностью ЕИС нотариата
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            40 руб. за первую-десятую страницу; далее 20 руб., начиная с
                            одиннадцатой страницы
                        </td>
                        <td className="column3 style15 s">
                            50 руб. за каждую страницу выписки
                        </td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row140">
                        <td className="column0 style5 s">
                            Выдача выписки из реестра регистрации нотариальных действий
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            10руб.
                        </td>
                        <td className="column3 style7 n">540руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row141">
                        <td className="column0 style9 s">
                            Выдача выписки из реестра распоряжений об отмене доверенностей, за
                            исключением нотариально удостоверенных доверенностей
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style14 s">
                            50 руб. за каждую страницу выписки
                        </td>
                        <td className="column4 style19 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row142">
                        <td className="column0 style5 s">
                            Розыск документов, переданных нотариусу на хранение из архивов
                            государственных нотариальных контор и архивов нотариусов , сложивших
                            полномочия, в том числе выдача их копий
                        </td>
                        <td className="column1 style12 s style4" colSpan={2}>
                            10 руб. за страницу копии
                        </td>
                        <td className="column3 style7 n">2,200руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row143">
                        <td className="column0 style5 s">
                            Удостоверение факта наличия сведений в Едином государственном
                            реестре недвижимости о фамилии, об имени, отчестве и о дате рождения
                            гражданина, являющегося правообладателем объекта недвижимости или
                            лицо, в пользу которого зарегистрированы ограничения права или
                            обременения объекта недвижимости
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            300руб.
                        </td>
                        <td className="column3 style7 n">150руб.</td>
                        <td className="column4 style7 n">696руб.</td>
                        <td className="column5">&nbsp;</td>
                    </tr>
                    <tr className="row144">
                        <td className="column0 style5 s">
                            За совершение прочих нотариальных действий
                        </td>
                        <td className="column1 style8 n style4" colSpan={2}>
                            100руб.
                        </td>
                        <td className="column3 style7 n">2,200руб.</td>
                        <td className="column4 style16 null" />
                        <td className="column5">&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default TariffTable;