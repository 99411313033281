import AppBody from "components/AppBody";
import Header from "components/Sections/Header";
import Footer from "components/Sections/Footer";

import "./index.scoped.css"

function NotFound() {
    const header = [
        { title: "О нас", link: "/about" },
        { title: "Контакты", link: "/contacts" },
        { title: "Услуги", link: "/services?service=inheritance" },
        { title: "Тарифы", link: "/tariffs" }
    ];

    return (
        <AppBody>
            <Header links={header} />
            <div className="block__wrapper">
                <div class="container">
                    <h1>404</h1>
                    <p>
                        Что-то пошло не так, <br /> кажется, такой страницы нет.
                    </p>
                    <small>Попробуйте вернутся на <a href="/">главную страницу</a></small>
                    <div class="circle small"></div>
                    <div class="circle medium"></div>
                    <div class="circle big"></div>
                </div>
            </div>
        </AppBody>
    );
}

export default NotFound;